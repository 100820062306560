import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Banded Good Mornings 3×10, Hollow Rocks 3x:30`}</p>
    <p>{`Deadlifts 4×4\\@80%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9:`}</p>
    <p>{`Deadlifts (225/155)(RX+255/175)`}</p>
    <p>{`Wall Balls (20/14)(RX+ 30/20)`}</p>
    <p><em parentName="p">{`*`}{`20 Calorie Assault Bike After Each Round.`}</em></p>
    <p><strong parentName="p">{`*`}{`Our Thanksgiving Morning Workout will be at 9:00am tomorrow. Meet at
the Big 4 Walking Bridge for a fun run/workout over the bridge.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      